import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import './css/App.css';
import Loading from './components/Animations/Loading.jsx';
import RootLayout from './components/RootLayout.jsx';
import ErrorPage from './pages/Error.jsx';
import { Suspense, lazy, useEffect } from 'react';
import BackgroundSwitcher from './components/BackgroundImage/BackgroundSwitcher.jsx';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { queryClient } from './util/http.js';
import PhotoPopup from './components/popups/PhotoPopup.jsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import Analytics from './components/Analytics'; // Adjust the path if necessary
import { useState } from "react";
import ExpoPopup from "./components/popups/ExpoPopup.jsx";
import { AnimatePresence } from 'framer-motion';
import ogImage from './images/og_image.png';

const HomePage = lazy(() => import('./pages/Home'));
const ExhibitionPage = lazy(() => import('./pages/Exhibition'));
const LibraryPage = lazy(() => import('./pages/Library'));
const MaterialPage = lazy(() => import('./pages/Material'));


function App() {
  const { t, i18n } = useTranslation(['General']);
  const currentLanguage = i18n.language;

  const [isPopupVisible, setPopupVisible] = useState(false);
  function closePopup() {
    setPopupVisible(false);
  }
  function openPopup() {
    setPopupVisible(true);
  }
  useEffect(() => {
    ReactGA.initialize('G-BWE9TCNWG4');
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    const validLanguages = ['en', 'fr', 'de']; // List of supported language codes
    const pathSegments = window.location.pathname.split('/').filter(Boolean);
    const pathLang = pathSegments[0]; // Extract the first segment as the language code

    let newPath = '';
    // Get stored language and validate it
    const storedLanguage = localStorage.getItem('user-lang');

    if (validLanguages.includes(pathLang)) {
      // If the URL has a valid language, sync it with i18n and localStorage
      if (i18n.language !== pathLang) {
        i18n.changeLanguage(pathLang).then(() => {
          localStorage.setItem('user-lang', pathLang);
        });
      }
    } else if (storedLanguage && validLanguages.includes(storedLanguage)) {
      // If no valid language in the URL, use the stored language
      newPath = `/${storedLanguage}${window.location.pathname}`;
      i18n.changeLanguage(storedLanguage).then(() => {
        window.location.replace(newPath);
      });
    } else {
      // Default to 'de' if neither the URL nor storage has a valid language
      newPath = `/de${window.location.pathname}`;
      i18n.changeLanguage('de').then(() => {
        localStorage.setItem('user-lang', 'de');
        window.location.replace(newPath);

      });
      return null; // Prevent further rendering
    }
  }, [i18n]); // Only run on mount



  const router = createBrowserRouter([
    {
      path: '/'
    },
    {
      path: '/:lang',
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      error: (error) => <ErrorPage error={error} />,
      children: [
        {
          index: true,
          element:
            <Suspense fallback={<Loading />}>
              <BackgroundSwitcher activePage="home" />
              <HomePage openPopup={openPopup} />
              <AnimatePresence>
                {isPopupVisible && <ExpoPopup closePopup={closePopup} />}
              </AnimatePresence>
            </Suspense>,
        },
        {
          path: 'exhibition',
          element:
            <Suspense fallback={<Loading />}>
              <BackgroundSwitcher activePage="exhibition" />
              <ExhibitionPage openExpoPopup={openPopup} />
              <AnimatePresence>
                {isPopupVisible && <ExpoPopup closePopup={closePopup} />}
              </AnimatePresence>
            </Suspense>,
          loader: () => import('./pages/Exhibition').then((module) => module.loader(currentLanguage)),
          children: [
            {
              path: ':photo_id',
              element:
                <Suspense fallback={<Loading />}>
                  <PhotoPopup />
                </Suspense>,
            },
          ],
        },
        {
          path: 'library',
          element:
            <Suspense fallback={<Loading />}>
              <BackgroundSwitcher activePage='library' />
              <LibraryPage openExpoPopup={openPopup} />
              <AnimatePresence>
                {isPopupVisible && <ExpoPopup closePopup={closePopup} />}
              </AnimatePresence>
            </Suspense>,
          loader: () => import('./pages/Library').then(module => module.loader())
        },
        {
          path: 'material',
          element:
            <Suspense fallback={<Loading />}>
              <BackgroundSwitcher activePage="material" />
              <MaterialPage openPopup={openPopup} />
              <AnimatePresence>
                {isPopupVisible && <ExpoPopup closePopup={closePopup} />}
              </AnimatePresence>
            </Suspense>,
          loader: () => import('./pages/Material').then((module) => module.loader(currentLanguage)),
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <HelmetProvider>
        <Helmet>
          <html lang={i18n.language} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={t('description')} />
          <meta property="og:image" content={ogImage} />

          <meta property="description" content={t('description')} />
        </Helmet>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router}>
            <Analytics />
          </RouterProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
