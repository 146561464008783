import { motion } from "framer-motion";
import closeIcon from '../../images/close.png';
import classes from './MobileNavigation.module.css';
import { NavLink } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import ExpoPopup from "./ExpoPopup";
import { useState } from "react";

export default function MobileNavigation({ menuItems, closeMobileNav, className, isActive }) {
    const { t, i18n } = useTranslation(["General"]);
    const [isPopupVisible, setPopupVisible] = useState(false);
    function closePopup() {
        setPopupVisible(false);
    }
    function openPopup() {
        setPopupVisible(true);
    }
    function closeDialog() {
        closeMobileNav();
    }

    return (
        <>
            <motion.div className={classes.dialog}
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 30 }}
                transition={{ type: 'spring', duration: 1 }}
            >
                <button onClick={closeDialog}><img src={closeIcon} alt='close' /></button>
                <ul className={`${classes.menu} ${className}`}>
                    <li key='0'>
                        <NavLink
                            className={({ isActive }) => isActive ? classes.active : undefined}
                            to={`/${i18n.language}`}
                            onClick={closeDialog}>{t('Home')}
                        </NavLink>
                    </li>
                    {menuItems.map((item, index) => <li key={index}>
                        <NavLink
                            className={({ isActive }) => isActive ? classes.active : undefined}
                            to={item.path} onClick={closeDialog}>

                            {({ isActive }) => (
                                <>
                                    {item.label}
                                    {isActive && (
                                        <div
                                            className={classes.activeTabIndicator}>
                                        </div>
                                    )}
                                </>
                            )}
                        </NavLink>
                    </li>)}
                    <li key='4'>
                        <NavLink onClick={openPopup}>{t('Locate the exhibition')}</NavLink>
                    </li>
                </ul>
            </motion.div>
            <AnimatePresence>
                {isPopupVisible && <ExpoPopup closePopup={closePopup} />}
            </AnimatePresence>
        </>
    )
}